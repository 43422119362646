import React from 'react'
import EmailTemplateEdit from '../../../components/Email/TemplateEdit'
import FSRoute from '../../../components/FSRoute'
import { isBrowser } from '../../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <FSRoute
        path="/email/template/edit"
        component={EmailTemplateEdit}
        fsOnly="false"
        data={data}
      />
      <FSRoute
        path="/email/template/edit/:templateid"
        component={EmailTemplateEdit}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App
