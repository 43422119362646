import React from 'react'
import Layout from '../Layout'
import View from '../View'
import Form from '../Form/email-template'
import { globalHistory } from '@reach/router'
import MultiStepForm from '../MultiStepForm'
import FormTab from '../MultiStepForm/FormTab'
import Slider from '../Slider'
import {
  setPageData,
  saveNewEmailTemplate,
  getEmailTemplate,
  updateEmailTemplate,
  getEmailLog,
  checkFeatureChange } from '../../utils/email'
import {
  getSiteIndex
} from '../../utils/site-index'
import { parseQuery } from '../../utils/query'
import { validURL } from '../../utils/validation'
import EmailPreview from '../EmailPreview'
import URLAutocomplete from '../URLAutocomplete'
import * as styles from '../Form/form.module.css'
import done from '../../../static/img/done.jpg'
import { Link } from 'gatsby'
import RichTextEditor from './RichTextEditor'
import { getCurrentUser } from '../../utils/auth'
import WaitLoader from '../WaitLoader'
require('../Tabs/tabs.module.css')

class EmailTemplateEdit extends React.Component {

  constructor(props) {
    super(props)
    var domain = 'https://www.forms-surfaces.com'
    var pageQuery = parseQuery(globalHistory.location.search)
    if(pageQuery[0]){
      var arr = pageQuery.split('/')
      domain = arr[0] + '//' + arr[2]
    }
    var user = getCurrentUser()
    if(user.fsOnly){
      if(user.fsOnly.websiteDomain){
        domain = user.fsOnly.websiteDomain
      }
    }
    this.state = {
      to: `exampleuser@forms-surfaces.net`,
      subject: '',
      userMessage: '',
      showUserImage: false,
      url: '',
      url2: '',
      url3: '',
      errorMessage: '',
      submitError: '',
      emailBody: '<p>Generating email from template.</p>',
      overrideTemplate: '',
      random: 0,
      errors: [],
      isFormValid: false,
      domain: domain,
      query: {
        url: 'https://www.forms-surfaces.com/apex-bench'
      },
      useTemplateOverride: false,
      images: [],
      pages: [],
      siteIndex: [],
      pageTitle: 'CREATE A NEW EMAIL TEMPLATE',
      loading: true
    }
  }

  componentDidMount() {
    getSiteIndex(this.state.domain, function(err, siteIndex){
      if(err){
        this.setState({errorMessage: err.message});
        return
      }
      this.setState({
        siteIndex: siteIndex
      })
    }.bind(this))
    var pageQuery = this.state.query;
    if(globalHistory.location.search){
      pageQuery = parseQuery(globalHistory.location.search)
      if(pageQuery.url){
        var url = pageQuery.url
        var arr = url.split('/')
        var domain = arr[0] + '//' + arr[2]
        this.setState({domain:domain})
        this.setState({ query: pageQuery }, function() {

          this.setState({url:this.state.query.url});
          this.featureChange(this.state.query.url, 0);
          setPageData(this.state.query.url, false, function(err, data){
            if(err){
              this.setState({errorMessage: err.message,loading: false});
              return
            }
            this.setState({ subject: data.title, loading: false })
          }.bind(this))

        })
      }
      if(pageQuery.log){
        getEmailLog({logid: pageQuery.log}, function(err, response){
          if(err){
            console.log(err)
            this.setState({errorMessage: err.message})
          }
          if(response){
            if(response.log){
              if(response.log.errorMessage){
                this.setState({errorMessage: response.log.errorMessage, loading: false})
              }
              this.setState({
                subject: response.log.subject,
                userMessage: response.log.userMessage,
                domain: response.log.domain,
                pages: response.log.pagesShared,
                template: response.log.template,
                overrideTemplate: response.log.overrideTemplate,
                loading: false
              }, function(){
                this.validate()
              })
            }
          }
        }.bind(this))
      }
    }
    if(this.props.templateid){
      this.setState({
        pageTitle: 'EMAIL TEMPLATE EDIT'
      })
      getEmailTemplate({templateid: this.props.templateid}, function(err, response){
        if(err){
          console.log(err)
          this.setState({errorMessage: err.message, loading: false})
        }
        if(response){
          if(response.template){
            if(response.template.errorMessage){
              this.setState({errorMessage: response.template.errorMessage, loading: false})
            }
            let useTemplateOverride = false
            let overrideTemplate = ''
            if(response.template.overrideTemplate){
              useTemplateOverride = true
              overrideTemplate = response.template.overrideTemplate
            }
            this.setState({
              subject: response.template.subject,
              userMessage: response.template.userMessage,
              domain: response.template.domain,
              pages: response.template.pagesShared,
              showUserImage: response.template.showUserImage,
              useTemplateOverride: useTemplateOverride,
              overrideTemplate: overrideTemplate,
              loading: false
            }, function(){
              this.validate()
            })
          }
        }
      }.bind(this))
    }else{
      this.setState({
        loading: false
      })
    }
  }

  validate() {
    var url = '';
    if(this.state.pages[0]){
      url = this.state.pages[0].url
    }
    this.setState({errors: {
      subject: this.state.subject.length < 1,
      url: url.length < 1,
    }}, function(){
      this.isFormValid()
    })
  }

  isFormValid(){
    let isValid = true;
    Object.keys(this.state.errors).forEach(function(key, index){
      if(this.state.errors[key] === true){
        isValid = false
      }
    }.bind(this))
    this.setState({isFormValid: isValid})
  }

  handleUpdate(event) {
    if(event.target.name === 'userMessage'){
      event.target.value = event.target.value.replace(/<a\b[^>]*>/ig,"").replace(/<\/a>/ig, "")
    }
    var updateObject = {
      [event.target.name]: event.target.value,
    }
    this.setState(updateObject, function(){
      this.validate()
    })
  }

  handleSubmit() {
    var updateMessage = function(text) {
      this.setState({ submitError: text })
    }.bind(this)
    if(this.props.templateid){
      updateEmailTemplate(this.props.templateid, this.state, function(message) {
        updateMessage(message)
      })
    }else{
      saveNewEmailTemplate(this.state, function(message) {
        updateMessage(message)
      })
    }
  }

  featureChange(value, index) {
    let currentState = {...this.state}
    checkFeatureChange(value, currentState, index, this.state.siteIndex, function(updateObject){
      this.setState(updateObject, function(){
        this.validate()
      })
    }.bind(this))
  }

  imageUpdate = (url, index) => {
    this.setState(prevState => {
      const pages = [...prevState.pages]
      pages[index].imageUrl = url
      return {pages:pages}
    })
  }

  getInitvalue = (index) => {
    if(this.state.pages[index]){
      return this.state.pages[index].url
    }
  }

  updatePageMessage = (value, index) => {
    value = value.replace(/<a\b[^>]*>/i,"").replace(/<\/a>/i, "")
    this.setState(prevState => {
      const pages = [...prevState.pages]
      pages[index].userMessage = value
      return {pages:pages}
    })
  }

  render() {
    let templateSaved = <div><img src={done} alt="You did it!" /><p>High Five! Your email template has been saved to your email templates’ list. Need to make changes? Click 'Back' to edit your email template, or click <a href="/email/template/edit">'Create A New Template'</a> to start a new one.</p></div>
    if(this.state.submitError){
      templateSaved = <div><img src="https://media.giphy.com/media/mG8ys6DrOPk7m/giphy.gif" alt="Darn!" /><p>Something seems to have gone wrong in sending your email.</p><p>{this.state.submitError}</p></div>
    }
    return (
      <Layout>
      <View title={this.state.pageTitle?this.state.pageTitle:'CREATE A NEW TEMPLATE'}
      parent={{
        url: '/email/list-templates/',
        title: 'EMAIL TEMPLATES'
      }}
      >
      <div className="row col-xs-12">
      <p>Follow this step-by-step process to create your own F+S branded email template.</p>
      <p>First, pick an engaging subject line for your email. Make sure to check the resource link for best practices to get some excellent pointers.</p>
      <p>Then, try to customize your intro message to grab your audience's attention. Your profile picture will be selected by default; uncheck it if you wish to remove it.</p>
      <p>Now, select your F+S website content: you can add up to three pages at a time by just typing the page's name and – if applicable - select the corresponding media and edit the related page content blurb.</p>
      <p>Finally, preview your email before saving it to your email templates’ list. It's that simple!</p>
      </div>
      <div className="row center-xs">
        <div className="email-form col-xs-12 col-sm-12 col-md-12">
          <WaitLoader loading={this.state.loading} />
          <div className="box" style={{
            margin: '0px auto'
          }}>
          <MultiStepForm
            formValid={this.state.isFormValid}
          >
            <FormTab label="Compose" key="1" nextLabel="PREVIEW">
              <Form
                handleUpdate={e => this.handleUpdate(e)}
                handleSubmit={e => this.handleSubmit(e)}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                showUserImage={this.state.showUserImage}
              />
              <label className={styles[`form__label`]}>
                First Feature
                <URLAutocomplete
                  domain={this.state.domain}
                  siteIndex={this.state.siteIndex}
                  initValue={this.getInitvalue(0)}
                  onChangeFunction={value => this.featureChange(value, 0)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[0] ? (
              <>
              <Slider images={this.state.pages[0].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[0].imageUrl} index="0" />
              <div style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
              <RichTextEditor
                onUpdate={this.updatePageMessage}
                body={this.state.pages[0].userMessage}
                index={0}
                />
              </div>
              </>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Second Feature
                <URLAutocomplete
                  domain={this.state.domain}
                  siteIndex={this.state.siteIndex}
                  onChangeFunction={value => this.featureChange(value, 1)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[1] ? (
                <div style={{
                  display: (this.state.useTemplateOverride ? 'none' : 'block')
                }}>
                <Slider images={this.state.pages[1].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[1].imageUrl} index="1" />
                <RichTextEditor
                  onUpdate={this.updatePageMessage}
                  body={this.state.pages[1].userMessage}
                  index={1}
                  />
                </div>) : null}
              <label className={styles[`form__label`]} style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
                Third Feature
                <URLAutocomplete
                  domain={this.state.domain}
                  siteIndex={this.state.siteIndex}
                  onChangeFunction={value => this.featureChange(value, 2)}
                >
                </URLAutocomplete>
              </label>
              {this.state.pages[2] ? (
              <div style={{
                display: (this.state.useTemplateOverride ? 'none' : 'block')
              }}>
              <Slider images={this.state.pages[2].images} imageUpdate={this.imageUpdate} initUrl={this.state.pages[2].imageUrl} index="2" />
              <RichTextEditor
                onUpdate={this.updatePageMessage}
                body={this.state.pages[2].userMessage}
                index={2}
                />
              </div>) : null}
            </FormTab>
            <FormTab label="PREVIEW" key="2" nextLabel="SAVE TEMPLATE" prevLabel="BACK">
              <EmailPreview
                to={this.state.to}
                subject={this.state.subject}
                userMessage={this.state.userMessage}
                showUserImage={this.state.showUserImage}
                domain={this.state.domain}
                pages={this.state.pages}
                overrideTemplate={this.state.overrideTemplate}
              />
              <p>If the email template preview looks good, go on and click 'Save Template.' Need to make changes? Click 'Back' to edit your email template.</p>
            </FormTab>
            <FormTab label="SAVE" key="3" prevLabel="BACK" nextLabel="" nextAction={this.handleSubmit.bind(this)} resetLabel="CREATE ANOTHER TEMPLATE">
              {templateSaved}
            </FormTab>
          </MultiStepForm>
          <label className="error">{this.state.errorMessage}</label>
          </div>
        </div>
        </div>
      </View>
      </Layout>
    )
  }
}

export default EmailTemplateEdit
