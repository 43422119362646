import React from 'react'
import * as styles from './form.module.css'
import { navigate } from '@reach/router'
import RichTextEditor from '../Email/RichTextEditor'
import BestPracticeLink from './best-practices'

const EmailTemplate = (({ handleSubmit, handleUpdate, url, url2, url3, subject, userMessage, showUserImage, errors, history }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
      navigate(`/email`)
    }}
  >
    <p className={styles[`form__instructions`]} />
    <label className={styles[`form__label`]}>
      Subject Line
      <input
        className={styles[`form__input`]}
        type="text"
        name="subject"
        value={subject}
        placeholder="TYPE AN ENTICING SUBJECT LINE"
        onChange={handleUpdate}
      />
    </label>
    <BestPracticeLink />
    <label className={styles[`form__label`]} htmlFor="userMessage">
      Intro Message
    </label>
      <RichTextEditor
      id="userMessaage"
      name="userMessage"
      body={userMessage}
      placeholder="TYPE AN ENGAGING INTRO MESSAGE"
      onUpdate={(value) => handleUpdate({target: {
        name: 'userMessage',
        value: value
      }})}
      />
      <label className={styles[`form__label`] + ' ' + styles[`for__checkbox`]}>
      Show User Image (if available)
      <input
      className={styles[`form__input__checkbox`]}
      type="checkbox"
      name="showUserImage"
      checked={showUserImage}
      onChange={(e) => handleUpdate({target: {
            name: 'showUserImage',
            value: e.target.checked
          }})}
      />
      </label>
  </form>
))

export default EmailTemplate
